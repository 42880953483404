export default {
  "pretext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello, my name is"])},
  "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenifer Prochnow"])},
  "maincopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am a Software Developer from Hamburg, Germany.<br>Nice to meet you!"])},
  "description": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I develop websites and apps"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This webpage was created using Vue.js. <br><br><b>Skills</b><br>REST APIs<br>Frontend & Backend<br>Progressive Web Apps<br>E-Commerce<br><br><b>Languages</b><br>PHP7<br>JavaScript(Vue, React Native)<br>SQL(Postgres, MySql)<br><br>"])}
  },
  "contact": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a question or like me to do some programming for you? <br>I am excited to hear from you!"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail adress"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message"])},
    "successHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for your message!"])},
    "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I will get back to you as soon as possible."])},
    "errorHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some error occured while trying to send your email"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact me directly via following email address: hey[at]jeniferprochnow.com"])}
  },
  "work": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These are the projects I've been working on recently."])},
    "projects": {
      "pawpal": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Get-To-Know App for dog owners was inspired by dating apps. Built in functionalities are the classic dating app swipe, the ability to configure own customer profiles, login and bearer authorization as well as a basic chat functionality."])}
      },
      "cwe": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This app provides a Google Map with over 2000 Coworking Spaces. The API retrieves the data for the markers from the website https://coworkingmap.org with a pagecrawler. The app was deployed to the Google Play Store"])}
      },
      "bluerizon": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Wordpress eCommerce Shop with over 1000 different product variaties. I also built custom Gutenberg Blocks for this shop."])}
      },
      "asset_tracker": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress: A dashboard to have an overview over different assets. Consists of API and Frontend part and contains a login through Json Web Token. Gets actual market data from a third party API and calculates the current portfolio value without needing to connect to private wallets or stock accounts."])}
      }
    }
  },
  "cv": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can see an overview of my work experience."])},
    "jobs": {
      "engineer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software Engineer"])},
        "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09/2021 - now"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Writing and testing performant, maintainable code in Backend as well as in Frontend, like for example moving and refactoring the backend system to a REST API, implementing new reservation feature or including a new paymentmethod in the checkout.</br></br>Communicate with multi-disciplinary teams of engineers, testers and product owners.</br></br>Technical conception of new features as well as feasability analysis for the integration of new technologies and methods."])}
      },
      "junior": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junior Developer"])},
        "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09/2019 - 08/2021"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Writing and testing performant, maintainable code in Backend as well as in Frontend, like for example a customer profile and an automatic refund and voucher feature.</br></br>Communicate with multi-disciplinary teams of engineers, testers and product owners."])}
      },
      "student2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working Student Web Development"])},
        "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02/2019 - 08/2019"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development of capsuled microsites and microservices for Clients. For example: Marketing OnePager, scan microsite for productcodes.</br></br> Adjustment of personalized CSS and JavaScript for clients."])}
      },
      "student1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working Student Web Development"])},
        "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09/2017 - 01/2019"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development of small frontend features and adjustment of responsie design for various B2B clients.</br></br>Transfer of an IOS App into a working Android App."])}
      }
    }
  },
  "skills": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical competencies in many areas with a key proficiency in Full Stack Web Deveopment."])}
  }
}