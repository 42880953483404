export default {
  "pretext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallo, mein Name ist"])},
  "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenifer Prochnow"])},
  "maincopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bin Software Entwicklerin aus Hamburg. <br>Schön dass Sie da sind."])},
  "description": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich entwickle Websites und Apps"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So ist beispielsweise diese Website mit Vue.js entstanden.<br><br><b>Skills</b><br>REST APIs<br>Frontend & Backend<br>Progressive Web Apps<br>E-Commerce<br><br><b>Sprachen</b><br>PHP7<br>JavaScript(Vue, React Native)<br>SQL(Postgres, MySql)<br><br>"])}
  },
  "contact": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie eine Frage oder möchten, dass ich etwas für Sie programmiere? <br>Ich freue mich, von Ihnen zu hören."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Adresse"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Nachricht"])},
    "successHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danke für Ihre Nachricht!"])},
    "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich werde mich schnellstmöglich bei Ihnen melden"])},
    "errorHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte kontaktieren Sie mich direkt unter folgender Adresse: hey[at]jeniferprochnow.com"])}
  },
  "work": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekte"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier sind Beipiele meiner letzten Arbeiten."])},
    "projects": {
      "pawpal": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Kennenlern App für Hundebesitzer wurde von Dating Apps inspiriert. Eingebaute Funktionen sind der klassische Dating App Swipe, die Anlage und Pflege von Kundenprofilen, Login und Bearer Authorisierung sowie das Verschicken von Chat Nachrichten bei erfolgtem Match."])}
      },
      "cwe": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese App enthält eine Google Karte mit über 2000 Markern. Die Marker Daten bezieht die API über einen Page Crawler von der Website https://coworkingmap.org. Die App wurde im Google Play Store deployed."])}
      },
      "bluerizon": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Wordpress eCommerce Shop mit über 1000 Produktvarianten, den ich eingerichtet habe. Für diesen Shop habe ich eigene Gutenberg Blöcke entwickelt."])}
      },
      "asset_tracker": {
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Arbeit: Ein Dashboard zur Übersicht über verschiedene Assets. Besteht aus API und Frontend Teil und enthält Login über Json Webtoken. Bezieht aktuelle Marktdaten über eine API und berechnet daraus den aktuellen Wert der persönlichen Investitionen ohne sich mit der Wallet oder dem Aktiendepot verbinden zu müssen."])}
      }
    }
  },
  "cv": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebenslauf"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier ist eine Übersicht meiner bisherigen beruflichen Laufbahn"])},
    "jobs": {
      "engineer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software Engineer"])},
        "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09/2021 - jetzt"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreiben und Testen von performantem, wartbarem Code im Backend und im Frontend Bereich, beispielsweise Umzug des Backend Systems in eine REST API, Umbau von Reservierungslogik und Implementierung einer neuen Zahlmethode. </br></br>Abstimmung mit multi-disziplinären Teams aus Entwicklern, Testern und Product Ownern.</br></br>Technische Konzeption von neuen Features sowie Machbarkeitsanalyse für Integration neuer Technologien."])}
      },
      "junior": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junior Developer"])},
        "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09/2019 - 08/2021"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreiben und Testen von performantem, wartbarem Code im Backend und im Frontend Bereich, beispielsweise Kundenprofil und Erstattungs Feature. </br></br>Abstimmung mit multi-disziplinären Teams aus Entwicklern, Testern und Product Ownern."])}
      },
      "student2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkstudentin Web Entwicklung"])},
        "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02/2019 - 08/2019"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen von abgekapselten Microsites und Microservices auf Nachfrage von Kunden. Beispiele: Marketing OnePager, Produktcode Scan Microsite. </br></br>Anpassen von personalisiertem CSS und JavaScript für einzelne Mandanten."])}
      },
      "student1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkstudentin Web Entwicklung"])},
        "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09/2017 - 01/2019"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwicklung kleinerer Frontend Features und Anpassung von Responsive Design für unterschiedliche B2B Kunden. </br></br>Übertragung einer fertigen IOS App in eine Android App."])}
      }
    }
  },
  "skills": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technische Kompetenzen in vielen Bereichen mit Fokus auf FullStack Web Entwicklung."])}
  }
}